import { Fragment } from 'react'

import { NextPage, GetStaticProps } from 'next'

import HomePageLayout from 'components/ui/Layouts/HomePageLayout'
import { MetaTags } from 'components/shared'
import { GRANTED_WEB_SETTINGS } from 'gql/queries'
import { GrantedWebSettingsQuery, GrantedWebSettingsQueryVariables } from 'gql'
import { GRANTED_SETTINGS_NAMES } from 'constants/constant'
import { addApolloState, initializeApollo } from '~services/apollo'
import EditSectionWrapper from 'components/home/block/edit-section-wrapper'
import { useGrantedWebSections } from 'hooks'
import sections from 'components/sections'

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo()

  await apolloClient.query<GrantedWebSettingsQuery, GrantedWebSettingsQueryVariables>({
    query: GRANTED_WEB_SETTINGS,
    variables: {
      name: GRANTED_SETTINGS_NAMES.home,
    },
  })

  return addApolloState(apolloClient, {
    props: {},
    revalidate: 65, // seconds
  })
}

const HomePage: NextPage = () => {
  const { data } = useGrantedWebSections({
    variables: {
      name: GRANTED_SETTINGS_NAMES.home,
    },
  })

  return (
    <>
      <MetaTags />
      <HomePageLayout>
        {data.map(({ settings, type, name }, index) =>
          sections[type] ? (
            <Fragment key={name}>
              <EditSectionWrapper name={name} href={`/admin-v2/home-page-sections?name=${name}`}>
                {sections[type](settings, index)}
              </EditSectionWrapper>
            </Fragment>
          ) : null
        )}
      </HomePageLayout>
    </>
  )
}

export default HomePage
