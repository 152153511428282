import { FC, ReactNode } from 'react'

import { Layout } from '~ui'

type ContentProps = {
  children: ReactNode
}

const HomePageLayout: FC<ContentProps> = ({ children }) => (
  <>
    <Layout.Banner />
    <Layout.HomeHeader />
    <main>{children}</main>
    <Layout.Footer />
  </>
)

export default HomePageLayout
