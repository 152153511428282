import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { GrantedWebSettingsQuery, GrantedWebSettingsQueryVariables } from 'gql'
import { GRANTED_WEB_SETTINGS } from 'gql/queries'

type UseGrantedWebSectionsParams = {
  variables: GrantedWebSettingsQueryVariables
}

const useGrantedWebSections = ({ variables }: UseGrantedWebSectionsParams) => {
  const { data, loading } = useQuery<GrantedWebSettingsQuery, GrantedWebSettingsQueryVariables>(
    GRANTED_WEB_SETTINGS,
    {
      variables,
    }
  )

  const settings = useMemo(
    () => data?.grantedWebSettings.edges[0]?.node || { settings: '{}', sections: [] },
    [data?.grantedWebSettings.edges]
  )

  const parsedSettings = useMemo(
    () => ({
      ...settings,
      settings: JSON.parse(settings.settings),
    }),
    [settings]
  )

  const sections = useMemo(
    () =>
      parsedSettings.sections
        .map((section) => ({
          ...section,
          settings: JSON.parse(section.settings),
        }))
        .sort((a, b) => {
          const { sections: sectionsOrder } = parsedSettings.settings
          return sectionsOrder.indexOf(Number(a.pk)) - sectionsOrder.indexOf(Number(b.pk))
        }),
    [parsedSettings.sections, parsedSettings.settings]
  )

  return { data: sections, loading }
}

export default useGrantedWebSections
